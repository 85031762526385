import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'axios-progress-bar/dist/nprogress.css';
import '../../Assests/Styles/Styles.scss';
import {USERGROUPSINFO} from '../../Common/Constants/constants';
import appConfig from '../../Environment/environments';
import axiosInstance from '../Shared/Interceptor/interceptor';
import { encryptData } from '../../Common/Utilities/utility';
import Users from '../../Common/Services/Users';
import detailsStyles from "./detailsStyles";
import { countryList } from "./constant";
import Button from '@material-ui/core/Button';
import { LangOpt } from '../../Common/Utilities/languagesutils';
import { validateEmailRegex, updateLogoutTime } from '../../Common/Utilities/utility';
import ToastMessages from '../Shared/Toast/ToastMessages';
import Info from '../../Assests/Images/Info.svg';
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router";
import '../../Assests/Styles/Styles.scss';
import {getUserType} from '../../Common/Utilities/utility';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const Details = () => {
    const classes = useStyles();
    const { account, formPanel } = detailsStyles();
    const [userDetail, setUserDetail] = useState([]);
    const [statusMessage, setStatusMessage] = useState("Loading...");

    useEffect(() => {
        getUserDetails();
    }, []);

    function getUserDetails () {
      axiosInstance.get(`${appConfig.api.development}${USERGROUPSINFO}`, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          }
      }).then(async res => {
          if(res == null || res.data == null || res.data.length == 0){
            setStatusMessage("No groups found");
          } else {
            let adminUsers = [];
            res.data.forEach((item) => {
              if(item.admins != null){
                item.admins.split(", ").forEach((userRec, index) => {
                  if(index > 2)
                    return false;
                  else
                    adminUsers.push(userRec);
                });
              }             

              item.admins = adminUsers;
              adminUsers = [];
            });
            setUserDetail(res.data);            
          }            
      });
    }

    return (
        <>
          <h1 className={account}>Details</h1>
          {
              userDetail &&
              <div className={formPanel}>
                  <ExpansionPanel expanded={true}>                      
                      <ExpansionPanelSummary className="appCategory show" expandIcon={<ExpandMoreIcon />}>
                        <span>Groups</span>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                          {/* <Typography>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                              sit amet blandit leo lobortis eget.
                          </Typography> */}
                          <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start" className="gridAppData">
                          {
                            (userDetail.length > 0)? userDetail.map((rec, index) =>
                              <Grid item zeroMinWidth key={index} classes={{ item: classes.appLevelItem }}>
                                  <Card tabIndex="0" className={classes.card} style={{"width":"256px"}}>
                                    <div className="EditAppContainer">
                                    <CardHeader
                                      classes={{
                                        root: classes.cardHeader,
                                        title: classes.appTitle,
                                      }}
                                      title={rec.groupName}
                                    />
                                  </div>
                                  <CardContent className={classes.cardContent}>                                      
                                      <div><b>Admins</b>:
                                      {(rec.admins.length > 0)? rec.admins.map((admin) =>
                                        <div> {admin} </div>) 
                                        : <span> No admins</span>
                                      }
                                      </div>
                                      <br></br>
                                      <div><b>Invited by</b>: {rec.createdBy}</div>
                                      <br></br>
                                      <div><b>Status</b>: {(rec.isActive)? "Active" : "Inactive"}</div>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ) : <span>{statusMessage}</span>
                          }
                        </Grid>
                      </ExpansionPanelDetails>
                  </ExpansionPanel>
              </div>
          }
      </>
    )
}

export default Details;