import React from 'react';
import axiosInstance from '../Shared/Interceptor/interceptor'
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import appConfig from '../../Environment/environments';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';
import '../../Assests/Styles/Styles.scss';
import Typography from '@material-ui/core/Typography';
import BlackEdit from '../../Assests/Images/edit-black.svg';
import { GETGROUPAPPDETAILS, AppsStyles, APPSLABELTEXT, APPSNOTFOUNDTEXT, APPSLOADINGTEXT, ADDAPP, ADDEDITAPP, OTHERS, ENTER } from '../../Common/Constants/constants'
import RightDrawer from '../Shared/RightDrawer/RightDrawer';
import AppDetails from './AppAddEdit/AppDetails';
import { setGlobalAdmin } from '../../redux/Actions/globalAdminAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getImageUrl,getIDToken,encryptData } from '../../Common/Utilities/utility'
import MenuIcon from "@material-ui/icons/Menu";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { ExpandMore } from '@material-ui/icons';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import jwt from "jsonwebtoken";
const styles = theme => AppsStyles;
const { sign } = jwt;
class Apps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "", appLists: [], appFilterLists: [], flag: false, loading: true, isGlobalAdmin: false, 
      open: false, anchorEl: null, childApps: [], expanded: "panel1"
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.getAllApps();
  }

  handleAccordian = panel => (event, expanded) => {
    if (expanded) {
      this.setState({ expanded: panel });
    } else {
      this.setState({ expanded: false });
    }
  }

  handleClick = (event, appData) => {
    this.setState({ childApps: appData.childApps, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  keyPressed = (e) => {
    this.setState({searchText : e.target.value});
    if (e.key === ENTER) {
      this.performSearch(e.target.value);
    }
  };

  onClearSearch = async () => {
    if(this.state.searchText != ""){
    var searchAppList = (this.state.appFilterLists.length > 0)? 
      this.state.appFilterLists : this.state.appLists;
      this.setState({ appLists: searchAppList, searchText : ""});
    }
  }

  onSearchClick = () => {
    if(this.state.searchText != ""){
      this.performSearch(this.state.searchText);
    }
  }

  performSearch = async (filterText) => {
    var searchAppList = [];

    if(filterText == ""){
      searchAppList = (this.state.appFilterLists.length > 0)? this.state.appFilterLists : this.state.appLists;
    }else{      
      var allAppList = [];
      if(this.state.appFilterLists.length == 0){
        this.setState({ appFilterLists: this.state.appLists});
        allAppList = this.state.appLists;
      }else{
        allAppList = this.state.appFilterLists;
      }      
      
      allAppList.forEach(app => {
        const filteredApp = app.data.filter(rec => 
          rec.name.toLowerCase().includes(filterText.toLowerCase()));

        if(filteredApp.length > 0){          
          searchAppList.push({...app, data: filteredApp});
        }
      });
    }

    this.setState({ appLists: searchAppList, searchText : filterText});
  };

  onChangeOfSearchText = (e) => {    
    this.setState({ searchText: e.target.value});
    this.performSearch(e.target.value);
  };

  getAllApps() {
    loadProgressBar({ easing: 'ease-in-out', speed: 100, showSpinner: false }, axiosInstance)
    axiosInstance.get(`${appConfig.api.development}${GETGROUPAPPDETAILS}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        const { appLists, isGlobalAdmin } = res.data;
        let objectMap = appLists.reduce(function (oldVal, newVal) {
          if (!oldVal[newVal.category]) oldVal[newVal.category] = [];
          oldVal[newVal.category].push(newVal)
          return oldVal;
        }, {})
        let sortedKeys = Object.keys(objectMap).sort();
        let groupedData = sortedKeys.reduce(function (val, key) {
          val.push({ "category": key, "data": objectMap[key] });
          return val;
        }, []);
        this.props.setGlobalAdmin(isGlobalAdmin);
        (appLists.length > 0) ? this.setState({ appLists: groupedData, loading: false, isGlobalAdmin: isGlobalAdmin }) : this.setState({ flag: true, isGlobalAdmin: isGlobalAdmin });
      })
  }

  addAnApp = (e) => {
    this.toggleDrawer(e, true)
  }

  routeTo = (appURL) => {
    //const idToken = localStorage.getItem("msal.idtoken");
    const idToken = getIDToken();
    if (idToken)
    {
     let updatedToken=  this.updateAppUrl(idToken,appURL)
     window.open(`${appURL}?umtoken=${updatedToken}`)
    }
    
  }

  updateAppUrl=(idToken,appURL)=>{
    const  updatedPayload = {
      exp: idToken.exp,
      nbf: idToken.iat,
      ver: "2", // not in ID token, available in accessToken
      iss: idToken.iss,
      sub: idToken.sub,
      aud: idToken.aud,
      nonce: idToken.nonce,
      iat: idToken.iat,
      auth_time: idToken.auth_time,
      idp: idToken.idp, //not available in cognito - refer https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens
      given_name: idToken.given_name,
      family_name: idToken.family_name,
      name: idToken.family_name,
      oid: idToken.oid,
      country: idToken.country, // not added in cognito yet. Need to add while sign up
      emails: idToken.emails,
      usertype: idToken.usertype,
      username: idToken.username,
      hvacusername: idToken.hvacusername,
      state: idToken.state,
      city: idToken.city,
      privileges: idToken.privileges,
      app_privileges:idToken.app_privileges,
      app_url:encryptData(appURL)
    };
    const secret =
      "{'alg':'RS256','e':'AQAB','kid':'a/PbTTdADbIExtiPBacVG8IKLOqzm5ExtkCy/6OfyU8=','kty':'RSA','n':'pZxsmeqe4k04Ghl5g-z6MZHqZ5SeYBwEv1UphWotkREYvw5FrfVVhh_3DFHnKIRswKdGDti6FePdyZjra3xJfGQ0LdobEj439a4HIr1bCfcdKbTYOiwc3aN5EkHNKZcDXjDA7sLVMMwr7mqM8aQ0w6sQovYeB-Tj7HECRbYID_087y7QgC3PBBqZab62ERHY6U03W_lhNRXW-jlL4lk-6-l50dC11PMGfn2ZA739PWf1wFmeF97pyNOxGhxaD6JowUUk_Qvf5hj74fnadTM2mSuA7vFWL-Gtp6Jo_49jYZek1h7tppcvTvr-IMeFPRRZfgtgdgUUsY5IOZ7QI2egjQ','use':'sig'}";
    const signedToken =  sign(updatedPayload, secret);
    return  signedToken;
  }

  toggleDrawer = (event, closeDrawer) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({
      open: closeDrawer
    })
  };

  appEditClick = (apps) => {
    this.props.history.push({
      pathname: ADDEDITAPP,
      id: apps.id,
      type: 'edit'
    })
  }

  appLabelComponent = (classes) => {
    return (
      <>
        <Typography gutterBottom classes={{ root: classes.appsListTitle }}>
          {APPSLABELTEXT}
          
          {(this.state.isGlobalAdmin) && <Button variant="contained" onClick={(e) => this.addAnApp(e)} disableRipple color="primary" classes={{ root: classes.addAppButton, label: classes.addAppButtonLabel }}>
            {ADDAPP}
          </Button>}
          <span className="groupSearch">
                <TextField
                  value={this.state.searchText}
                   onChange={this.onChangeOfSearchText}
                   onKeyDown={this.keyPressed}
                  title='Type app name'
                  placeholder="Type app name"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon onClick={this.onSearchClick} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <ClearIcon onClick={this.onClearSearch} />
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: "dashboardSearchBox",
                  }}             
                />                
              </span>
        </Typography>
        <RightDrawer open={this.state.open}>
          <AppDetails onClose={(e) => this.toggleDrawer(e, false)} type="add" />
        </RightDrawer>
      </>
    )
  }

  render() {
    const { classes } = this.props;
    const { anchorEl, childApps } = this.state;
    if (this.state.flag) {
      return (
        <div className="AppsListContainer">
          {this.appLabelComponent(classes)}
          <Typography gutterBottom classes={{ root: classes.appsListNotFound }}>{APPSNOTFOUNDTEXT}</Typography>
        </div>
      )
    }
    else {
      return (
        <div>
          <div className="AppsListContainer">
            {this.appLabelComponent(classes)}
            {
              <Grid container spacing={3} classes={{ container: classes.gridContainer }}>
                {
                  (this.state.loading) ? 
                  <Typography gutterBottom classes={{ root: classes.appsListNotFound }}>{APPSLOADINGTEXT}</Typography> : 
                  (this.state.appLists.length > 0) ?
                  this.state.appLists.map((apps, index) =>
                    <>
                      <ExpansionPanel key={index} expanded={this.state.expanded === (`panel${index + 1}`)}
                        onChange={this.handleAccordian(`panel${index + 1}`)}>
                        <ExpansionPanelSummary
                          className={"appCategory " + (this.state.expanded === (`panel${index + 1}`) ? 'show' : '')} expandIcon={<ExpandMore />}>
                          <span>{(apps.category === "null") ? OTHERS : apps.category}</span>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>

                          <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start" className="gridAppData">
                            {
                              apps.data.map((appData, index) =>
                                <Grid item xs={12} sm={6} md={3} lg={2} zeroMinWidth key={index} classes={{ item: classes.appLevelItem }}>
                                  <Card tabIndex="0" className={classes.card}>
                                    <div className="EditAppContainer">
                                      <CardHeader
                                        classes={{
                                          root: classes.cardHeader,
                                          title: classes.appTitle,
                                        }}
                                        title={appData.name}
                                        onClick={() => this.routeTo(appData.url)}
                                      />
                                      {(appData.isAppAdmin || this.state.isGlobalAdmin) && <div className="EditAppIconContainer" onClick={() => this.appEditClick(appData)}>
                                        <img width="12" height="12" className="AppEditIcon" src={BlackEdit} xs={2} />
                                      </div>}
                                    </div>
                                    <CardContent className={classes.cardContent}>
                                      <CardMedia
                                        className={classes.media}
                                        onClick={() => this.routeTo(appData.url)}
                                        src={getImageUrl(appData.id, appData.logoImageType)}
                                        component="img"
                                        onError={e => {
                                          e.target.src = `${appConfig.api.blobUrl}default.png`
                                        }}
                                        title={appData.logoImageName} />
                                    </CardContent>
                                    {(appData.childApps.length !== 0) &&
                                      <>
                                        <Button
                                          aria-controls="basic-menu"
                                          aria-haspopup="true"
                                          className="menuIcon"
                                          title="Apps"
                                          onClick={(event) => this.handleClick(event, appData)}
                                        >
                                          <MenuIcon />
                                        </Button>
                                        <Menu
                                          id="basic-menu"
                                          MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                          }}
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          getContentAnchorEl={null}
                                          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                          transformOrigin={{ vertical: "top", horizontal: "left" }}
                                          onClose={this.handleClose.bind(this)}
                                          classes={{
                                            paper: classes.childAppMenu
                                          }}
                                        >
                                          {
                                            childApps && childApps.map((child, childIndex) =>
                                              <MenuItem index={childIndex} className="childMenuApp" >
                                                <ListItemIcon className="childMenuImg">
                                                  <img width="30" src={getImageUrl(child.id, child.logoImageType)} onError={e => {
                                                    e.target.src = `${appConfig.api.blobUrl}default.png`
                                                  }} />
                                                </ListItemIcon>
                                                <ListItemText className="childMenuApp" onClick={() => this.routeTo(child.url)} >{child.name}</ListItemText>
                                                {(child.isAppAdmin || child.isGlobalAdmin) && <div className="editChildApp" onClick={() => this.appEditClick(child)}>
                                                  <img width="12" height="12" className="AppEditIcon" src={BlackEdit} xs={2} />
                                                </div>}
                                              </MenuItem>
                                            )
                                          }
                                        </Menu>
                                      </>
                                    }
                                  </Card>
                                </Grid>
                              )
                            }
                          </Grid>
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </>
                  ) : <>No matches found</>
                }
              </Grid>
            }
          </div>
        </div>
      )
    }
  }
}

Apps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(null, { setGlobalAdmin })(withStyles(styles)(Apps)));