import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'axios-progress-bar/dist/nprogress.css';
import '../../Assests/Styles/Styles.scss';
import {
    USERDETAIL, UPDATEUSERDETAILS, USERUPDATESUCCESS, ERRORMSG, GROUPEXPIRYINFO, FIRSTNAME, CANCELTEXT,
    LASTNAME, EMAILADDRESS, COUNTRY, STATE, CITY, LANGUAGE, APPNOCHANGES, SAVETEXT, ACCOUNT, EDITEMAILDESCRIPTION, DASHBOARD, USERUPDATENOTE
} from '../../Common/Constants/constants';
import appConfig from '../../Environment/environments';
import axiosInstance from '../Shared/Interceptor/interceptor';
import { encryptData } from '../../Common/Utilities/utility';
import Users from '../../Common/Services/Users';
import editProfileStyles from "./editProfileStyles";
import { countryList } from "./constant";
import Button from '@material-ui/core/Button';
import { LangOpt } from '../../Common/Utilities/languagesutils';
import { validateEmailRegex, updateLogoutTime } from '../../Common/Utilities/utility';
import ToastMessages from '../Shared/Toast/ToastMessages';
import Info from '../../Assests/Images/Info.svg';
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router";
import '../../Assests/Styles/Styles.scss';
import {getUserType} from '../../Common/Utilities/utility';

var userInstance = new Users();
const Profile = () => {
    const { account, formfield, cancelBtn, normalBtn, formPanel, formFieldLabel, 
        buttons, validError, divNotes, inputError } = editProfileStyles();
    const [userDetail, setUserDetail] = useState({});
    const [statusMessage, setStatusMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [messageVariant, setMessageVariant] = useState("");
    const [stateList, setStateList] = useState([{"name": "--State/Region--"}]);
    const [cityList, setCityList] = useState(["--City--"]);
    const [formData, setFormData] = useState([
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false },
        { "error": "", changed: false, originalValue: "", disabled: false, required: true }
    ]);

    useEffect(() => {
        getUserDetails();
    }, []);
    const history = useHistory();
    const getEmailID = () => {
        return userInstance.getUserEmail();
    }

    function lastNameChange(e) {
        let lName = e.target.value;
        formData[0].value = lName;
        formData[0].changed = (formData[0].originalValue == lName) ? false : true;

        setUserDetail(prevData => {
            return {
                ...prevData,
                lastname: lName
            }
        });
        if (lName == null || lName.trim() == "")
            formData[0].error = "Last name is required";
        else
            formData[0].error = "";
    }

    function firstNameChange(e) {
        let fName = e.target.value;
        formData[1].value = fName;
        formData[1].changed = (formData[1].originalValue == fName) ? false : true;
        setUserDetail(prevData => {
            return {
                ...prevData,
                firstname: fName
            }
        });

        if (fName == null || fName.trim() == "")
            formData[1].error = "First name is required";
        else
            formData[1].error = "";
    }

    function emailChange(e) {
        let email = e.target.value;
        formData[2].value = email;
        formData[2].changed = (formData[2].originalValue == email) ? false : true;

        setUserDetail(prevData => {
            return {
                ...prevData,
                emailAddress: email
            }
        });

        if (email == null || email == "")
            formData[2].error = "Email is required";
        else if (validateEmailRegex(e.target.value))
            formData[2].error = "Please enter valid email address";
        else
            formData[2].error = "";
    }

    async function countryNameChange(e) {
        let country = e.target.value;
        formData[3].value = country;
        formData[3].changed = (formData[3].originalValue == country) ? false : true;

        setUserDetail(prevData => {
            return {
                ...prevData,
                countryName: country
            }
        });

        try{
            setStateList([{"name": "Loading..."}]);
            setCityList(["--City--"]);
            formData[3].disabled = true;
            await GetCountryStates({"country": country}, function(response) {
                formData[3].disabled = false;
                setStateList([{"name": "--State/Region--"}].concat(response.data.states));
            });
        }catch(error){
            console.log(error);
        }
    }

    const GetStateCities = async(obj, callback) => {  
        // Get all states's cities
       await axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, obj)
        .then(res => {
          if(res && res.data && res.data.data.length == 0){
            res.data.data = ["Other"];
          }
          callback(res.data);
        })
    };

    async function GetCountryStates(obj, callback) {
        // Get all country's states
        await axios.post(`https://countriesnow.space/api/v0.1/countries/states`,  obj)
        .then(res => {
            callback(res.data);
        });
    };

    async function stateNameChange(e) {
        let state = e.target.value;
        formData[5].value = state;
        formData[5].changed = (formData[5].originalValue == state) ? false : true;

        setUserDetail(prevData => {
            return {
                ...prevData,
                state: state
            }
        });

        setCityList(["Loading..."]);
        formData[5].disabled = true;
        formData[3].disabled = true;
        await GetStateCities({"country": (typeof formData[3].value == "undefined")? formData[3].originalValue : formData[3].value, "state": state}, function(response) {    
            formData[5].disabled = false;
            formData[3].disabled = false;
            setCityList(["--City--"].concat(response.data));
        });
    }

    function cityNameChange(e) {
        let city = e.target.value;
        formData[6].value = city;
        formData[6].changed = (formData[6].originalValue == city) ? false : true;

        setUserDetail(prevData => {
            return {
                ...prevData,
                city: city
            }
        });
    }

    function languageChange(e) {
        let language = e.target.value;
        formData[4].value = language;
        formData[4].changed = (formData[4].originalValue == language) ? false : true;
        setUserDetail(prevData => {
            return {
                ...prevData,
                langCode: language
            }
        });
    }

    function phoneNoChange(e) {
        let phone = e.target.value;
        formData[7].value = phone;
        formData[7].changed = (formData[7].originalValue == phone) ? false : true;

        setUserDetail(prevData => {
            return {
                ...prevData,
                phone: phone
            }
        });

        if (phone != null && phone.trim() != "" && phone.length != 10)
            formData[7].error = "Phone no should be 10 digits";
        else
            formData[7].error = "";
    }
    
    function acceptTouChange(e) {
        let touVal = e.target.checked;
        formData[8].value = touVal;
        const updatedItems = formData.map((item,index)=> {
            // If the item's id matches the id being updated, update its name
            if (index == 8) {
                return { ...item, error: (touVal == false) ? "Terms of Use is required" : "" }; // Create a new object with updated name
            }
            return item; // Otherwise, return the original item
        });
        // Update state with the new array
        setFormData(updatedItems);
    }

    function getUserDetails () {
        var emailid = getEmailID();
        axiosInstance.get(`${appConfig.api.development}${USERDETAIL}${encryptData(emailid).toString()}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(async res => {
            if(res != null && res.data != null)
                res.data.userType = getUserType();
            
            setUserDetail(res.data);
            formData[0].originalValue = res.data.lastname;
            formData[1].originalValue = res.data.firstname;
            formData[2].originalValue = res.data.emailAddress;
            formData[3].originalValue = res.data.countryName;
            formData[4].originalValue = res.data.langCode;
            formData[5].originalValue = res.data.state;
            formData[6].originalValue = res.data.city;
            formData[7].originalValue = res.data.phone;

            // Load states/cities based on country selected
            if(res.data.countryName != null && res.data.countryName != ''){
                setStateList([{"name": "Loading..."}]);
                await GetCountryStates({"country": res.data.countryName}, function(response) {
                    setStateList([{"name": "--State/Region--"}].concat(response.data.states));
                });

                if(res.data.city != null && res.data.city != ''){
                    setCityList(["Loading..."]);
                    try{
                        await GetStateCities({"country": res.data.countryName, "state": res.data.state}, function(response) {           
                            setCityList(["--City--"].concat(response.data));
                            //setCityName(response.data[1]);
                        });
                    }
                    catch(error){
                        setCityList(["--City--"].concat(["Other"]));
                        console.log(error);
                    }                   
                }
            }
        });
    }

    const isValuesChangd = () => {
        let data = {};
        let isChanged = false;
        let isValid = true;

        if(formData[0].error != '' || formData[1].error != '' || formData[2].error != ''){
            isValid = false;            
        }

        if(userDetail.hasExternaloginOnly){
            if((formData[0].value == "" || typeof formData[0].value == 'undefined') && formData[0].originalValue == ""){
                const updatedItems = formData.map((item,index)=> {
                    if (index == 0) 
                        return { ...item, error: "Last name is required" }; 
                    return item;
                });
                setFormData(updatedItems);
                isValid = false; 
            }

            if((formData[1].value == "" || typeof formData[1].value == 'undefined') && formData[1].originalValue == ""){
                const updatedItems = formData.map((item,index)=> {
                    if (index == 1) {
                        return { ...item, error: "First name is required" }; 
                    }
                    return item;
                });
                setFormData(updatedItems);
                isValid = false; 
            }

            if((formData[2].value == "" || typeof formData[2].value == 'undefined') && formData[2].originalValue == ""){
                const updatedItems = formData.map((item,index)=> {
                    if (index == 2) 
                        return { ...item, error: "Email is required" }; 
                    return item;
                });
                setFormData(updatedItems);
                isValid = false; 
            }
        }
        
        if (formData[0].changed) {
            data.lastname = formData[0].value;
            isChanged = true;            
        }

        if (formData[1].changed) {
            data.firstname = formData[1].value;
            isChanged = true;
        }

        if (formData[2].changed) {
            data.emailAddress = formData[2].value;
            isChanged = true;
        }

        if (formData[3].changed) {
            data.countryName = formData[3].value;
            isChanged = true;
        }

        if (formData[4].changed) {
            data.langCode = formData[4].value;
            isChanged = true;
        }

        if (formData[5].changed) {
            data.state = formData[5].value;
            isChanged = true;
        }

        if (formData[6].changed) {
            data.city = formData[6].value;
            isChanged = true;
        }
        
        if (formData[7].changed) 
        {
            if( formData[7].error != ""){
                isValid = false;
            }
            else{
                data.phone = formData[7].value;
                isChanged = true;
            }            
        }

        if(formData[8].error != '' || formData[8].error != '' || formData[8].value == undefined || formData[8].value == false){
            formData[8].error = "Terms of Use is required";
            isValid = false;

             // Use map to create a new array with modified items
            const updatedItems = formData.map((item,index)=> {
                // If the item's id matches the id being updated, update its name
                if (index == 8) {
                    return { ...item, error: "Terms of Use is required" }; // Create a new object with updated name
                }
                return item; // Otherwise, return the original item
            });
        
            // Update state with the new array
            setFormData(updatedItems);
        }

        return { isChanged, isValid, data };
    }

    const saveUserDetails = (event) => {
        event.preventDefault();
        // Check if any changes are there
        const { isChanged, isValid, data } = isValuesChangd();

        if(isValid){
            if (!isChanged) {
                setStatusMessage(true);
                setMessage(APPNOCHANGES);
                setMessageVariant("warning");
            } else {
                axiosInstance.put(`${appConfig.api.development}${UPDATEUSERDETAILS}?isEdit=true`, data, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }).then(res => {
                    if (res) {
                        setStatusMessage(true);
                        setMessage(USERUPDATESUCCESS);
                        setMessageVariant("success");

                        // if email changed and external user it should do logout
                        if (formData[2].changed 
                            || (userDetail.userType != 1 && (formData[3].changed || formData[5].changed || formData[6].changed))
                            || (userDetail.userType == 1 && (formData[0].changed || formData[1].changed 
                                || formData[3].changed || formData[5].changed || formData[6].changed)))
                            updateLogoutTime();
                        else if (formData[4].changed)
                            window.location.reload();
                    }
                }).catch(error => {
                    let errorMessage = ERRORMSG;
                    if ((error.response.data.message.indexOf("domain") > -1) ||
                        (error.response.data.message.indexOf("exist") > -1)) {
                        errorMessage = error.response.data.message;
                    }

                    setStatusMessage(true);
                    setMessage(errorMessage);
                    setMessageVariant("error");
                });
            }
        }
    }

    const handleCancel = () => {
        history.push(DASHBOARD);
    }

    const handleToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setStatusMessage(false);
    }

    return (
        <>
            <h1 className={account}>{ACCOUNT}</h1>
            {
                userDetail &&
                    <div className={formPanel}>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                {LASTNAME}
                            </div>
                            <input
                                title={formData[0].error}
                                name="lastName"
                                id="usrLatstName"
                                onChange={lastNameChange}
                                className={`${formfield} ${(formData[0].error != '')? inputError : ""}`}
                                value={userDetail.lastname}
                                disabled={!userDetail.hasExternaloginOnly}
                            />
                        </div>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                {FIRSTNAME}
                            </div>
                            <input
                                name="firstName"
                                id="usrFirstName"
                                title={formData[1].error}
                                onChange={firstNameChange}
                                className={`${formfield} ${(formData[1].error != '')? inputError : ""}`}
                                value={userDetail.firstname}
                                disabled={!userDetail.hasExternaloginOnly}
                            />
                        </div>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                {EMAILADDRESS}
                                <span>
                                    <span data-tip="By updating the email for this account, you will no longer be able to <br />access the application  with your current email and all the account information,  <br /> groups and apps data will now be mapped to your new email. <br /><br /><br /><br />On click of Save you will be redirected to login page <br />and you need to re-login with your new email to access the application." className="">
                                        <img className="EmailInfoIcon" src={Info} xs={2} />
                                    </span>
                                    <ReactTooltip place="bottom" type="dark" effect="float" multiline="true" />
                                </span>
                            </div>
                            <input
                                name="email"
                                id="usrEmail"
                                title={formData[2].error}
                                onChange={emailChange}
                                className={`${formfield} ${(formData[2].error != '')? inputError : ""}`}
                                value={userDetail.emailAddress}
                                disabled={!userDetail.hasExternaloginOnly}
                            />
                        </div>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                {COUNTRY}
                            </div>
                            <div className={validError}>
                                {formData[3].error}
                            </div>
                            <select name="country" id="usrCountry" onChange={countryNameChange} className={formfield} disabled={formData[3].disabled }>
                                {
                                    countryList.map((obj) => {
                                        return <option selected={obj.name == userDetail.countryName} key={obj.name} value={obj.name}>{obj.name}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                {STATE}
                            </div>
                            <div className={validError}>
                                {formData[5].error}
                            </div>
                            <select name="state" id="usrState" onChange={stateNameChange} className={formfield} disabled={formData[5].disabled }>
                                {
                                    stateList.map((obj) => {
                                        return <option selected={obj.name == userDetail.state} key={obj.name} value={obj.name}>{obj.name}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                {CITY}
                            </div>
                            <div className={validError}>
                                {formData[6].error}
                            </div>
                            <select name="city" id="usrCity" onChange={cityNameChange} className={formfield}>
                                {
                                    cityList.map((obj) => {
                                        return <option selected={obj == userDetail.city} key={obj} value={obj}>{obj}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                {LANGUAGE}
                            </div>
                            <select name="language" id="usrLanguage" onChange={languageChange} className={formfield}>
                                {
                                    LangOpt.map((obj) => {
                                        return <option selected={obj.fullLangCode == userDetail.langCode} key={obj.name} value={obj.fullLangCode}>{obj.name}</option>
                                    })
                                }
                            </select>
                        </div>

                        <div className="editUserColumn">
                            <div className={formFieldLabel}>
                                Phone No(10 Digits)
                            </div>
                            <input
                                type="number"
                                name="phoneNo"
                                id="usrPhoneNo"
                                title={formData[7].error}
                                onChange={phoneNoChange}
                                className={`${formfield} ${(formData[7].error != '')? inputError : ""}`}
                                value={userDetail.phone}
                            />
                        </div>
                        <div className={divNotes}>
                            <input type="checkbox" onChange={(event) => acceptTouChange(event)} id="acceptTou" title={formData[8].error}
                                name="acceptTou"  />                                
                            <span className={`${(formData[8].error != '')? inputError : ""}`} title={formData[8].error}>I accept the <a style={{"font-weight":"bold", "color":"#0033AB", "text-decoration": "none"}} href='https://www.carrier.com/carrier/en/worldwide/legal/terms-of-use/' target='_blank'>Terms of Use</a></span>
                        </div>
                        <div style={{"margin-top":"16px"}} className={divNotes}>
                            {USERUPDATENOTE}
                        </div>
                        <div className={buttons}>
                            <Button className={` ${normalBtn}`} onClick={saveUserDetails} >
                                {SAVETEXT}
                            </Button>
                            <Button className={message == ""? ` ${cancelBtn}`: ` ${cancelBtn}`} onClick={handleCancel} >
                                {CANCELTEXT}
                            </Button>
                        </div>

                        <ToastMessages statusMessage={statusMessage}
                        message={message}
                        variant={messageVariant}
                        toastHPosition={'center'}
                        toastVPosition={'top'}
                        close={handleToast} />

                    </div>
            }
        </>
    )
}

export default Profile;