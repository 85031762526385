import React from 'react';
import { DEFAULTGROUPTEXT,SAVETEXT,SettingsDrawerStyles,DEFAULTGROUP_APP,DEFAULTGROUPAPPSUCCESS,DEFAULTGROUPAPPFAIL,GETAPPDETAILS } from '../../../../Common/Constants/constants';
import ApplyAllGroups from '../../../Shared/ApplyAllGroups/ApplyAllGroups';
import { getGroupsData } from "../../../Groups/GetGroupsDataHelper";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import appConfig from '../../../../Environment/environments';
import axiosInstance from '../../../Shared/Interceptor/interceptor';
import ToastMessages from '../../../Shared/Toast/ToastMessages';
import { encryptData } from '../../../../Common/Utilities/utility';
const useStyles = SettingsDrawerStyles;


class ConfigureTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        data: [],
        statusMessage: false,
        message: "",
        toastVPosition: 'top',
        toastHPosition: 'center',
        messageVariant: '',
        loading: true,
        showGroupsInApps : false,
        isChildrenDisplayed: true,
        selectedGroup: null
        }
      }

      componentDidMount() 
      {
        getGroupsData(this.getChildrenSuccess, this.getChildrenFailure);
        if(this.state.selectedGroup==null)
        {
          this.getUpdatedData()
        }
      } 

      componentWillUnmount = () => this.setState({ data: [] })

      getChildrenSuccess = (responseData) => this.setState({ data: responseData, loading: false, showGroupsInApps:responseData.isPermissionRequired})

      getChildrenFailure = (error) => this.setState({ loading: false })

      saveSelectedGroups = (selectedGroup) => {  
        console.log(selectedGroup.value)
        this.setState({ selectedGroup: selectedGroup.value });
      }

      getUpdatedData = () => {
        if (this.props.Id) {
          axiosInstance.get(appConfig.api.development + GETAPPDETAILS + encryptData(this.props.Id).toString(), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          })
          .then(res => {
            this.setState({
              selectedGroup: res.data.defaultGroup,
              loading: false
            });
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            console.log("error:", error);
          })
        }
      }


    saveGroups=()=>{
      if (this.props.Id && this.state.selectedGroup) {
        let apiObject=
        {
          id:this.props.Id ,
          groupId:this.state.selectedGroup
        }
        const updatedAppGroupsObj = JSON.stringify(apiObject);
        axiosInstance.post(appConfig.api.development+DEFAULTGROUP_APP, updatedAppGroupsObj, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if(res.data===true)
          {
            this.setState({
              statusMessage: true,
              message: DEFAULTGROUPAPPSUCCESS,
              messageVariant: 'success'       
            });
          }
          else{
            this.setState({
              statusMessage: true,
              message: DEFAULTGROUPAPPFAIL,
              messageVariant: 'error'       
            });
          }
            
        })
        .catch((e) => {
          this.setState({
            statusMessage: true,
            message: DEFAULTGROUPAPPFAIL,
            messageVariant: "error",
            loading: false
          });
        });
      }
    }

    handleToast(event, reason) {
      if (reason === 'clickaway') {
        return;
      }
  
      this.setState({
        statusMessage: false
      });
    }

      render() {
        const { classes } = this.props;
    return (
        <>
        <div className="ManageUsersHeader">
        <ToastMessages
          statusMessage={this.state.statusMessage}
          message={this.state.message}
          variant={this.state.messageVariant}
          toastHPosition={this.state.toastHPosition}
          toastVPosition={this.state.toastVPosition}
          close={this.handleToast.bind(this)}
        />
      </div>
         <div className="groupsHeaderContainer">
        { (!this.showGroupsInApps && (this.state.loading === false)) && (
          <>
          <div className={classes.appAdminText}>{DEFAULTGROUPTEXT}</div>
          <div className={classes.appAdminButtonContainer}>
            <Button variant="contained" disabled={false} id="ManageDefaultGroup" disableRipple classes={{ root: classes.userAppButtonRoot }} onClick={() => this.saveGroups()} >
                  {SAVETEXT}
              </Button>
          </div>
          </>)
        }
        </div>
        <div  className={classes.appContainerList}>
          <div className={classes.rootSettings}>
           { this.state.data.map((ChildGroup, index) =>
          <ApplyAllGroups isChildrenDisplayed={this.state.isChildrenDisplayed} type={1} key={index} TreeLevel={0} groupData={ChildGroup} saveSelectedGroups={this.saveSelectedGroups}   selectedGroups={this.props.defaultGroupId && this.state.selectedGroup==null?this.props.defaultGroupId:this.state.selectedGroup}  />
          )}
        </div>
        </div>
        </>
        )
            }
}

export default withStyles(useStyles, { withTheme: true })(ConfigureTab);