export const countryList = [
    {
        "id": 1,
        "name": "Afghanistan",
        "code":"93"
    },
    {
        "id": 2,
        "name": "Albania",
        "code":"355"
    },
    {
        "id": 3,
        "name": "Algeria",
        "code":"213"
    },
    {
        "id": 4,
        "name": "Andorra",
        "code":"376"
    },
    {
        "id": 5,
        "name": "Angola",
        "code":"244"
    },
    {
        "id": 6,
        "name": "Antigua and Barbuda",
        "code":"1-268"
    },
    {
        "id": 7,
        "name": "Argentina",
        "code":"54"
    },
    {
        "id": 8,
        "name": "Armenia",
        "code":"374"
    },
    {
        "id": 9,
        "name": "Australia",
        "code":"61"
    },
    {
        "id": 10,
        "name": "Austria",
        "code":"43"
    },
    {
        "id": 11,
        "name": "Azerbaijan",
        "code":"994"
    },
    {
        "id": 12,
        "name": "The Bahamas",
        "code":"1-242"
    },
    {
        "id": 13,
        "name": "Bahrain",
        "code":"973"
    },
    {
        "id": 14,
        "name": "Bangladesh",
        "code":"880"
    },
    {
        "id": 15,
        "name": "Barbados",
        "code":"1-246"
    },
    {
        "id": 16,
        "name": "Belarus",
        "code":"375"
    },
    {
        "id": 17,
        "name": "Belgium",
        "code": "32"
    },
    {
        "id": 18,
        "name": "Belize",
        "code":"501"
    },
    {
        "id": 19,
        "name": "Benin",
        "code":"229"
    },
    {
        "id": 20,
        "name": "Bhutan",
        "code":"975"
    },
    {
        "id": 21,
        "name": "Bolivia",
        "code":"975"
    },
    {
        "id": 22,
        "name": "Bosnia and Herzegovina",
        "code":"975"
    },
    {
        "id": 23,
        "name": "Botswana",
        "code":"975"
    },
    {
        "id": 24,
        "name": "Brazil",
        "code":"975"
    },
    {
        "id": 25,
        "name": "Brunei",
        "code":"975"
    },
    {
        "id": 26,
        "name": "Bulgaria",
        "code":"975"
    },
    {
        "id": 27,
        "name": "Burkina Faso",
        "code":"975"
    },
    {
        "id": 28,
        "name": "Burundi",
        "code":"975"
    },
    {
        "id": 29,
        "name": "Cabo Verde",
        "code":"975"
    },
    {
        "id": 30,
        "name": "Cambodia",
        "code":"975"
    },
    {
        "id": 31,
        "name": "Cameroon",
        "code":"975"
    },
    {
        "id": 32,
        "name": "Canada",
        "code":"975"
    },
    {
        "id": 33,
        "name": "Central African Republic",
        "code":"975"
    },
    {
        "id": 34,
        "name": "Chad",
        "code":"975"
    },
    {
        "id": 35,
        "name": "Chile",
        "code":"975"
    },
    {
        "id": 36,
        "name": "China",
        "code":"975"
    },
    {
        "id": 37,
        "name": "Colombia",
        "code":"975"
    },
    {
        "id": 38,
        "name": "Comoros",
        "code":"975"
    },
    {
        "id": 39,
        "name": "Congo",
        "code":"975"
    },
    {
        "id": 40,
        "name": "Congo (DRC)",
        "code":"975"
    },
    {
        "id": 41,
        "name": "Costa Rica"
    },
    {
        "id": 42,
        "name": "Côte d’Ivoire",
        "code":"975"
    },
    {
        "id": 43,
        "name": "Croatia",
        "code":"975"
    },
    {
        "id": 44,
        "name": "Cuba",
        "code":"975"
    },
    {
        "id": 45,
        "name": "Cyprus",
        "code":"975"
    },
    {
        "id": 46,
        "name": "Czech Republic",
        "code":"975"
    },
    {
        "id": 47,
        "name": "Denmark",
        "code":"975"
    },
    {
        "id": 48,
        "name": "Djibouti"
    },
    {
        "id": 49,
        "name": "Dominica",
        "code":"975"
    },
    {
        "id": 50,
        "name": "Dominican Republic",
        "code":"975"
    },
    {
        "id": 51,
        "name": "Ecuador",
        "code":"975"
    },
    {
        "id": 52,
        "name": "Egypt",
        "code":"975"
    },
    {
        "id": 53,
        "name": "El Salvador",
        "code":"975"
    },
    {
        "id": 54,
        "name": "Equatorial Guinea",
        "code":"975"
    },
    {
        "id": 55,
        "name": "Eritrea",
        "code":"975"
    },
    {
        "id": 56,
        "name": "Estonia",
        "code":"975"
    },
    {
        "id": 57,
        "name": "Ethiopia",
        "code":"975"
    },
    {
        "id": 58,
        "name": "Fiji",
        "code":"975"
    },
    {
        "id": 59,
        "name": "Finland",
        "code":"975"
    },
    {
        "id": 60,
        "name": "France",
        "code":"975"
    },
    {
        "id": 61,
        "name": "Gabon",
        "code":"975"
    },
    {
        "id": 62,
        "name": "Georgia",
        "code":"975"
    },
    {
        "id": 63,
        "name": "Germany",
        "code":"975"
    },
    {
        "id": 64,
        "name": "Ghana",
        "code":"975"
    },
    {
        "id": 65,
        "name": "Greece",
        "code":"975"
    },
    {
        "id": 66,
        "name": "Grenada",
        "code":"975"
    },
    {
        "id": 67,
        "name": "Guatemala",
        "code":"975"
    },
    {
        "id": 68,
        "name": "Guinea",
        "code":"975"
    },
    {
        "id": 69,
        "name": "Guinea-Bissau"
    },
    {
        "id": 70,
        "name": "Guyana"
    },
    {
        "id": 71,
        "name": "Haiti"
    },
    {
        "id": 72,
        "name": "Honduras"
    },
    {
        "id": 73,
        "name": "Hungary"
    },
    {
        "id": 74,
        "name": "Iceland"
    },
    {
        "id": 75,
        "name": "India"
    },
    {
        "id": 76,
        "name": "Indonesia"
    },
    {
        "id": 77,
        "name": "Iran"
    },
    {
        "id": 78,
        "name": "Iraq"
    },
    {
        "id": 79,
        "name": "Ireland"
    },
    {
        "id": 80,
        "name": "Israel"
    },
    {
        "id": 81,
        "name": "Italy"
    },
    {
        "id": 82,
        "name": "Jamaica"
    },
    {
        "id": 83,
        "name": "Japan"
    },
    {
        "id": 84,
        "name": "Jordan"
    },
    {
        "id": 85,
        "name": "Kazakhstan"
    },
    {
        "id": 86,
        "name": "Kenya"
    },
    {
        "id": 87,
        "name": "Kiribati"
    },
    {
        "id": 88,
        "name": "Korea, North"
    },
    {
        "id": 89,
        "name": "Kosovo"
    },
    {
        "id": 90,
        "name": "Kuwait"
    },
    {
        "id": 91,
        "name": "Kyrgyzstan"
    },
    {
        "id": 92,
        "name": "Laos"
    },
    {
        "id": 93,
        "name": "Latvia"
    },
    {
        "id": 94,
        "name": "Lebanon"
    },
    {
        "id": 95,
        "name": "Lesotho"
    },
    {
        "id": 96,
        "name": "Liberia",
        "code":"213"
    },
    {
        "id": 97,
        "name": "Libya",
        "code":"213"
    },
    {
        "id": 98,
        "name": "Liechtenstein",
        "code":"213"
    },
    {
        "id": 99,
        "name": "Lithuania",
        "code":"213"
    },
    {
        "id": 100,
        "name": "Luxembourg",
        "code":"213"
    },
    {
        "id": 101,
        "name": "Madagascar",
        "code":"213"
    },
    {
        "id": 102,
        "name": "Malawi",
        "code":"213"
    },
    {
        "id": 103,
        "name": "Malaysia",
        "code":"213"
    },
    {
        "id": 104,
        "name": "Maldives",
        "code":"213"
    },
    {
        "id": 105,
        "name": "Mali",
        "code":"213"
    },
    {
        "id": 106,
        "name": "Malta",
        "code":"213"
    },
    {
        "id": 107,
        "name": "Marshall Islands",
        "code":"213"
    },
    {
        "id": 108,
        "name": "Mauritania",
        "code":"213"
    },
    {
        "id": 109,
        "name": "Mauritius",
        "code":"213"
    },
    {
        "id": 110,
        "name": "Mexico",
        "code":"213"
    },
    {
        "id": 111,
        "name": "Micronesia",
        "code":"213"
    },
    {
        "id": 112,
        "name": "Moldova",
        "code":"213"
    },
    {
        "id": 113,
        "name": "Monaco",
        "code":"213"
    },
    {
        "id": 114,
        "name": "Mongolia",
        "code":"213"
    },
    {
        "id": 115,
        "name": "Montenegro",
        "code":"213"
    },
    {
        "id": 116,
        "name": "Morocco",
        "code":"213"
    },
    {
        "id": 117,
        "name": "Mozambique",
        "code":"213"
    },
    {
        "id": 118,
        "name": "Myanmar",
        "code":"213"
    },
    {
        "id": 119,
        "name": "Namibia",
        "code":"213"
    },
    {
        "id": 120,
        "name": "Nauru",
        "code":"213"
    },
    {
        "id": 121,
        "name": "Nepal",
        "code":"213"
    },
    {
        "id": 122,
        "name": "Netherlands",
        "code":"213"
    },
    {
        "id": 123,
        "name": "New Zealand",
        "code":"213"
    },
    {
        "id": 124,
        "name": "Nicaragua",
        "code":"213"
    },
    {
        "id": 125,
        "name": "Niger",
        "code":"213"
    },
    {
        "id": 126,
        "name": "Nigeria",
        "code":"213"
    },
    {
        "id": 127,
        "name": "Norway",
        "code":"213"
    },
    {
        "id": 128,
        "name": "Oman",
        "code":"213"
    },
    {
        "id": 129,
        "name": "Pakistan",
        "code":"213"
    },
    {
        "id": 130,
        "name": "Palau",
        "code":"213"
    },
    {
        "id": 131,
        "name": "Panama",
        "code":"213"
    },
    {
        "id": 132,
        "name": "Papua New Guinea"
    },
    {
        "id": 133,
        "name": "Paraguay"
    },
    {
        "id": 134,
        "name": "Peru"
    },
    {
        "id": 135,
        "name": "Philippines"
    },
    {
        "id": 136,
        "name": "Poland"
    },
    {
        "id": 137,
        "name": "Portugal"
    },
    {
        "id": 138,
        "name": "Qatar"
    },
    {
        "id": 139,
        "name": "Romania"
    },
    {
        "id": 140,
        "name": "Russia"
    },
    {
        "id": 141,
        "name": "Rwanda"
    },
    {
        "id": 142,
        "name": "Saint Kitts and Nevis"
    },
    {
        "id": 143,
        "name": "Saint Lucia"
    },
    {
        "id": 144,
        "name": "Saint Vincent and the Grenadines"
    },
    {
        "id": 145,
        "name": "Samoa"
    },
    {
        "id": 146,
        "name": "San Marino"
    },
    {
        "id": 147,
        "name": "Sao Tome and Principe"
    },
    {
        "id": 148,
        "name": "Saudi Arabia"
    },
    {
        "id": 149,
        "name": "Senegal"
    },
    {
        "id": 150,
        "name": "Serbia"
    },
    {
        "id": 151,
        "name": "Seychelles"
    },
    {
        "id": 152,
        "name": "Sierra Leone"
    },
    {
        "id": 153,
        "name": "Singapore"
    },
    {
        "id": 154,
        "name": "Slovakia"
    },
    {
        "id": 155,
        "name": "Slovenia"
    },
    {
        "id": 156,
        "name": "Solomon Islands"
    },
    {
        "id": 157,
        "name": "Somalia"
    },
    {
        "id": 158,
        "name": "South Africa"
    },
    {
        "id": 159,
        "name": "Spain"
    },
    {
        "id": 160,
        "name": "Sri Lanka"
    },
    {
        "id": 161,
        "name": "Sudan"
    },
    {
        "id": 162,
        "name": "Suriname"
    },
    {
        "id": 163,
        "name": "Sweden"
    },
    {
        "id": 164,
        "name": "Switzerland"
    },
    {
        "id": 165,
        "name": "Syria"
    },
    {
        "id": 166,
        "name": "Taiwan"
    },
    {
        "id": 167,
        "name": "Tajikistan",
        "code":"213"
    },
    {
        "id": 168,
        "name": "Tanzania",
        "code":"213"
    },
    {
        "id": 169,
        "name": "Thailand",
        "code":"213"
    },
    {
        "id": 170,
        "name": "Togo",
        "code":"213"
    },
    {
        "id": 171,
        "name": "Tonga",
        "code":"213"
    },
    {
        "id": 172,
        "name": "Trinidad and Tobago",
        "code":"213"
    },
    {
        "id": 173,
        "name": "Tunisia",
        "code":"213"
    },
    {
        "id": 174,
        "name": "Turkey",
        "code":"213"
    },
    {
        "id": 175,
        "name": "Turkmenistan",
        "code":"213"
    },
    {
        "id": 176,
        "name": "Tuvalu",
        "code":"213"
    },
    {
        "id": 177,
        "name": "Uganda",
        "code":"213"
    },
    {
        "id": 178,
        "name": "Ukraine",
        "code":"213"
    },
    {
        "id": 179,
        "name": "United Arab Emirates",
        "code":"213"
    },
    {
        "id": 180,
        "name": "United Kingdom",
        "code":"213"
    },
    {
        "id": 181,
        "name": "United States",
        "code":"213"
    },
    {
        "id": 182,
        "name": "Uruguay",
        "code":"213"
    },
    {
        "id": 183,
        "name": "Uzbekistan",
        "code":"213"
    },
    {
        "id": 184,
        "name": "Vanuatu",
        "code":"213"
    },
    {
        "id": 185,
        "name": "Vatican City",
        "code":"213"
    },
    {
        "id": 186,
        "name": "Venezuela",
        "code":"213"
    },
    {
        "id": 187,
        "name": "Vietnam",
        "code":"213"
    },
    {
        "id": 188,
        "name": "Yemen",
        "code":"213"
    },
    {
        "id": 189,
        "name": "Zambia",
        "code":"213"
    },
    {
        "id": 190,
        "name": "Zimbabwe",
        "code":"213"
    },
    {
        "id": 191,
        "name": "East Timor&nbsp;(Timor-Leste)",
        "code":"213"
    },
    {
        "id": 192,
        "name": "Eswatini",
        "code":"213"
    },
    {
        "id": 193,
        "name": "The Gambia",
        "code":"213"
    },
    {
        "id": 194,
        "name": "Korea, South",
        "code":"213"
    },
    {
        "id": 195,
        "name": "North Macedonia",
        "code":"213"
    },
    {
        "id": 196,
        "name": "Sudan, South",
        "code":"93"
    }
];